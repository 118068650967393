import React, { useEffect, useState } from "react";

import { FormControl } from "@chakra-ui/react";

import YBLabel from "../../atoms/ChakraOverwrite/YBLabel";
import YBInput from "../../atoms/ChakraOverwrite/YBInput";
import YBPrimaryButton from "../../atoms/ChakraOverwrite/YBPrimaryButton";
import ErrorMessage from "../../atoms/ErrorMessage";

import { loginUserToBackend } from "../../../services/Users";
import useUserStore from "../../../storage/userStore";
import useFormInput from "../../../hooks/useFormInput";

const LoginForm = ({setSuccess}: {setSuccess: (success: boolean) => void}) => {
    const { setUser } = useUserStore();
    const [errMsg, setErrMsg] = useState("")

    const email = useFormInput("");
    const password = useFormInput("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const {response, error} = await loginUserToBackend(email.value, password.value);
        if (response) {
            setUser(response);
            setSuccess(true);
        }
        else if (error) {
            setErrMsg("Une erreur est survenue");
        }
    }

    useEffect(() => {
        setErrMsg("");
    }, [email, password]);

    return (
        <FormControl>
            <YBLabel
                color={"#6578F4"}
                label={"Email"}
                fontSize="xs"
                mt="6"
                mb="0"
            />
            <YBInput
                type="email"
                h={"40px"}
                {...email}
            />

            <YBLabel
                color={"#6578F4"}
                label={"Mot de passe"}
                fontSize="xs"
                mt="4"
                mb="0"
            />
            <YBInput
                type="password"
                h={"40px"}
                {...password}
            />
            <ErrorMessage errMsg={errMsg} />
            <YBPrimaryButton
                onClick={(e) => handleSubmit(e)}
                mt={10}
                w={"full"}
                h={"40px"}
                type="submit"
            >
                {"Se connecter"}
            </YBPrimaryButton>
        </FormControl>
    )
}

export default LoginForm;