import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import Sidebar from "./Sidebar";

const Layout = (props: any) => {
  const { children } = props;

  return (
    <Flex w={"100vw"}>
      <Box
        h={"100vh"}
        display={{ base: "none", sm: "block" }}
      >
        <Sidebar />
      </Box>
      <Box w={"100%"} overflowY={"scroll"} ml={{ base: "80px", md: "250px" }}>
        {children}
      </Box>
    </Flex>
  );
};

export default Layout;
