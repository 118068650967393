import React, { useState } from "react";

import { Navigate } from "react-router-dom";

import { Flex, Link, Center, Box, Stack, GridItem } from "@chakra-ui/react";
import { FaLinkedin } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";

import YBText from "../components/atoms/ChakraOverwrite/YBText";
import YBDivider from "../components/atoms/ChakraOverwrite/YBDivider";

import MainPageImage from "../assets/MainPageLogo.svg";
import LoginPageImage from "../assets/LoginPageImage.svg";
import LoginForm from "../components/molecules/forms/LoginForm";
import OneTapGoogleLogin from "../components/atoms/OneTapGoogleLogin";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LINKEDIN_LOGIN = BACKEND_URL + "/auth/linkedin";

const Login = () => {
    const [success, setSuccess] = useState(false);

    return (
        <>
            {success ? (
                <Navigate to="/"></Navigate>
            ) : (
                <>
                    <GridItem mt="1" pl="4" area={"header"}>
                        <img alt="" src={MainPageImage} />
                    </GridItem>
                    <Stack minH={"90vh"} direction={{ base: "column", md: "row" }}>
                        <Flex flex={5} align={"center"} justify={"center"} bg={"#EFEFEF"}>
                            <img alt="" src={LoginPageImage} />
                        </Flex>
                        <Flex p={8} flex={2} align={"center"} justify={"center"}>
                            <Stack spacing={4} w={"full"} maxW={"md"}>
                                <Box display="flex">
                                    <YBText
                                        color={"#585AFC"}
                                        fontSize="28"
                                    >
                                        {"Bienvenue sur le Back Office de YOUrban"} &emsp;
                                    </YBText>
                                    <MdWavingHand color={"#dfbf9f"} size={29} />
                                </Box>
                                <YBText
                                    color={"#6578F4"}
                                    fontSize="xs"
                                >
                                    {"Merci de vous connecter ici"}
                                </YBText>
                                <LoginForm setSuccess={setSuccess} />
                                <YBDivider label={"ou"} />
                                <Center>
                                    <OneTapGoogleLogin setSuccess={setSuccess}/>
                                </Center>
                                <Center>
                                    <Link
                                        mt="1"
                                        href={LINKEDIN_LOGIN}
                                    >
                                        <FaLinkedin color={"#3385ff"} size={40} />
                                    </Link>
                                </Center>
                            </Stack>
                        </Flex>
                    </Stack>
                </>
            )}
        </>
    );
};

export default Login;