import React from "react";
import YBLabel from "./ChakraOverwrite/YBLabel";

const ErrorMessage = ({errMsg}: {errMsg: string}) => {
    return (
        <>
            {errMsg && (
                <YBLabel
                    color="red"
                    label={errMsg}
                    fontSize="xs"
                    mt="6"
                    mb="0"
                />
            )}
        </>
    )
}

export default ErrorMessage;