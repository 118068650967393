import { useState } from "react";

export default function useFormInput(initialValue: string, placeholder: string = "") {
  const [value, setvalue] = useState(initialValue);
  const inputId = placeholder.toLowerCase().replace(/ /g, "_");

  function handleChange(e: any) {
    setvalue(e.target.value);
  }

  const inputProps = {
    id: inputId,
    value: value,
    onChange: handleChange,
    placeholder: placeholder
  };

  return inputProps;
}
