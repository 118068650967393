import React, { Suspense, useEffect } from 'react';

import { AbsoluteCenter, ChakraProvider, Spinner } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Layout from './components/layout/Layout';
import LoginPage from "./pages/LoginPage";

import routes from "./utils/routes";
import GlobalTheme from './utils/theme';

import useUserStore from "./storage/userStore";
import useAssetsStore from './storage/assetsStore';

import ProviderConnect from "./services/ProviderConnect";
import { checkToken, getUsersFromBackend } from './services/Users';
import { getAllAssetsFromBackend } from './services/Assets';

import { LoginUser } from './types/User';
import { log } from 'console';

const App = () => {
  const { loginUser, setUsers, setLoginUser, deleteLoginUser } = useUserStore();
  const { setAssets } = useAssetsStore();

  function RequireAuth({ children, redirectTo }: any) {
    return loginUser !== null ? children : <Navigate to={redirectTo} />;
  }

  const getAllUsers = async (loginUser: LoginUser) => {
    if (loginUser.providerName === "google" && loginUser?.email.endsWith("@go-yourban.com")) {

    const { response } = await getUsersFromBackend(loginUser.accessToken);
    if (response) {
      setUsers(response);
    }
  }
}

  const getAllAssets = async (loginUser: LoginUser) => {
    const { response } = await getAllAssetsFromBackend(loginUser.accessToken);
    if (response) {
      setAssets(response);
    }
  
}

useEffect(() => {
  if (loginUser ) {
      getAllUsers(loginUser);
      getAllAssets(loginUser);
    }
  }, [loginUser])

  useEffect(() => {
    if (loginUser !== null) {
      checkToken({ loginUser: loginUser, setLoginUser, deleteLoginUser });
    } else if (loginUser === null && localStorage.getItem('user')) {
      const connectedUser: LoginUser = JSON.parse(localStorage.getItem('user') ?? "")
      setLoginUser(connectedUser);
    }
  }, []);

  return (
    <ChakraProvider theme={GlobalTheme}>
      <BrowserRouter>
        <Suspense fallback={<AbsoluteCenter mt={"50%"}><Spinner /></AbsoluteCenter>}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login-provider" element={<ProviderConnect />} />
            <Route
              path="/*"
              element={
                <RequireAuth redirectTo="/login">
                  <Layout>
                    <Routes>
                      {routes.map((route) => (
                        <Route
                          key={route.name}
                          path={route.path}
                          element={<route.element />}
                        />
                      ))}
                    </Routes>
                  </Layout>
                </RequireAuth>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
