import axios from "axios";

import { BackendServiceReturn } from "../types/BackendServiceReturn";

import { Asset } from "../types/Asset";
import { StudyZone } from "../types/StudyZone";
import { getStudyZonesFromBackend } from "./StudyZone";
import { LocationZone } from "../types/LocationZone";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const addAssetToBackend = async (asset: any, accessToken: string) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/asset`, asset, { headers: { Authorization: `Bearer ${accessToken}` } });
        
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const updateAssetToBackend = async (asset: Asset, accessToken: string) => {
    try {
        const response = await axios.put(`${BACKEND_URL}/asset/${asset.id}`, asset, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const updateAssetLocationToBackend = async (newLocation: LocationZone, assetId: number, accessToken: string) => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/asset/${assetId}/location`, newLocation, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const getAssetByIdFromBackend = async (assetId: number, accessToken: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/asset/${assetId}/with-users`, { headers: { Authorization: `Bearer ${accessToken}` } })
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}


export const getAssetsByUserIdFromBackend = async (userId: number, accessToken: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/asset/${userId}/user/with-users`, { headers: { Authorization: `Bearer ${accessToken}` } })
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const getAllAssetsFromBackend = async (accessToken: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/asset`, { headers: { Authorization: `Bearer ${accessToken}` } })
        const assets: any = response.data;

        
        assets.map((asset: any) => {
           if (asset.subscription) {
            asset.subscription.endDate = new Date(asset.subscription.endDate)
            asset.subscription.startDate = new Date(asset.subscription.startDate)
            return (asset)
           }
        })
        return ({ response: assets, error: null } as BackendServiceReturn);
    } catch (error: any) {
        console.log(error)
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const deleteAssetToBackend = async (assetId: number, accessToken: string) => {
    try {
        const response = await axios.delete(`${BACKEND_URL}/asset/${assetId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const getAssetLocationFromBackend = async (assetId: number, accessToken: string) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/asset/${assetId}/location`, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

type Props = {
    asset: Asset,
    accessToken: string,
    setSelectedAsset: (asset: Asset) => void;
    setStudyZones: (studyZones: Array<StudyZone>) => void;
    setSelectedStudyZone: (studyZone: StudyZone) => void;
}
export const changeSelectedAsset = async ({asset, accessToken, setSelectedAsset, setStudyZones, setSelectedStudyZone}: Props) => {
    setSelectedAsset(asset);
    const {response} = await getStudyZonesFromBackend(accessToken, asset.id);
    if (response && response.length) {
        setStudyZones(response);
        setSelectedStudyZone(response[0])
    }
}