import React, { PropsWithChildren } from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";

import { Button, MenuButton, MenuButtonProps } from "@chakra-ui/react";

export default function YBSecondaryMenuButton(props: PropsWithChildren<MenuButtonProps>) {
    const { children, ...rest } = props;

    return (
        <MenuButton
            transition="z-index"
            transitionDuration='0.2s'
            transitionTimingFunction="ease-in-out"
            bgGradient="#EDF2F7"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            w={"100%"}
            {...rest}
        >
            {children}
        </MenuButton>
    )
}