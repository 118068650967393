import axios from "axios";

import { LoginUser, NewUser, User } from "../types/User";
import { BackendServiceReturn } from "../types/BackendServiceReturn";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LOGIN_URL = BACKEND_URL + "/auth/login";

export async function loginUserToBackend(email: string, password: string) {
    try {
        const response = await axios.post(
            LOGIN_URL,
            JSON.stringify({ email: email, password: password }),
            {
                headers: { "Content-Type": "application/json" },
            }
        );
        const user: LoginUser = response?.data;
        return ({ response: user, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export async function addUserToBackend(user: NewUser, accessToken: string) {
    try {
        const response = await axios.post(`${BACKEND_URL}/user`, user, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}

export const getUsersFromBackend = async (accessToken: string) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/user`, { headers: { Authorization: `Bearer ${accessToken}` } });
        
        return ({response:response.data, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const getUsersBySearchIdeaIdFromBackend = async (searchIdeaId : number, accessToken: string) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/user/search-idea/${searchIdeaId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response:response.data, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const getUsersByAssetIdFromBackend = async (assetId : number, accessToken: string) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/user/${assetId}/asset`, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response:response.data, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const updateUserToBackend = async (user: User | any, accessToken: string) => {
    try {
        const response = await axios.put(`${BACKEND_URL}/user/${user?.id}`, user, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response:response.data, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const updateUserAccessLevelAssetToBackend = async (userId: number , assetId: number, accessLevel: string, accessToken: string) => {
    try {
        const response = await axios.put(`${BACKEND_URL}/user/${userId}/asset/access-level`, {assetId, accessLevel}, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response:response.data, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const deleteUserToBackend = async (userId: number, accessToken: string) => {
    try {
        const response = await axios.delete(`${BACKEND_URL}/user/${userId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response: response, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

interface ICheckToken {
    loginUser: LoginUser,
    setLoginUser: (user: LoginUser) => void,
    deleteLoginUser: () => void;
}
export const checkToken = async ({ loginUser, setLoginUser, deleteLoginUser }: ICheckToken) => {
    if (loginUser?.id) {
        try {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/check-token`, { headers: { Authorization: `Bearer ${loginUser.accessToken}` } });
        } catch (error: any) {
            if (error?.response?.status === 401 || error?.response?.status === 403) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/user/refresh-token`,
                        { refreshToken: loginUser?.refreshToken },
                        {
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                    loginUser.accessToken = response.data.accessToken;
                    setLoginUser(loginUser);
                } catch (error: any) {
                    deleteLoginUser();
                }
            }
        }
    }
};

export const addAssetToUserInBackend = async (userId: number, assetId: number, accessToken: string) => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/user/${userId}/asset/connect`, {"assetId": assetId}, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response: response, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const disconnectAssetOfUserInBackend = async (userId: number, assetId: number, accessToken: string) => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/user/${userId}/asset/disconnect`, {"assetId": assetId}, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response: response, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}

export const connectUserToSearchIdeaInBackend = async (userIds: number[], searchIdeaId: number, assetId: number, accessToken: string) => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/user/search-idea/connect`, {"searchIdeaId": searchIdeaId, "userIds" : userIds, "assetId":assetId}, { headers: { Authorization: `Bearer ${accessToken}` } });
        return ({response: response, error:null} as BackendServiceReturn);
    } catch (error: any) {
        return ({response:null, error} as BackendServiceReturn);
    }
}