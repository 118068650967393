import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

import useUserStore from '../storage/userStore';

const URL = process.env.REACT_APP_BACKEND_URL;

const ProviderConnect = () => {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState<null | string>(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    
    const userId = params.get("userId");
    const refreshToken = params.get("token");
    
    const { setUser } = useUserStore();
    
    console.log("A")

    useEffect(() => {
        if (refreshToken) {
            axios.post(
                `${URL}/user/refresh-token`,
                JSON.stringify({ "refreshToken": refreshToken }),
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then((response) => {
                setAccessToken(response.data.accessToken);
            })
        }
    }, [refreshToken]);

    useEffect(() => {
        if (userId && accessToken) {
            axios.get(`${URL}/user/${userId}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((response) => {
                    let user = response.data;
                    if (user) {
                        user.accessToken = accessToken;
                        user.refreshToken = refreshToken;
                        console.log(user)
                        setUser(user);
                        navigate('/');
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des données de l'utilisateur", error);
                });
        }
    }, [userId, accessToken, navigate, refreshToken, setUser]);

    return (
        <></>
    );
}

export default ProviderConnect;
