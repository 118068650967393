import React, { useState } from "react";

import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { AiFillHome, AiOutlineCompass, AiOutlineDribbble, AiOutlineInbox, AiOutlineSetting, AiOutlineShop, AiOutlineTags, AiOutlineUser } from "react-icons/ai";

import NavTagItem from "../atoms/ChakraOverwrite/NavTagItem";
import NavItem from "../atoms/ChakraOverwrite/NavItem";

import { LinkItemProps } from "../../types/Layout";
import YBDivider from "../atoms/ChakraOverwrite/YBDivider";
import useUserStore from "../../storage/userStore";

export function LinkItemsGenerator() {
    const LinkItems: Array<LinkItemProps> = [
        { name: "Assets", icon: AiOutlineInbox, path: "/assets", button: true },
    ];
    return (LinkItems);
}

export function LinkItemsAdminGenerator() {
    const LinkItems: Array<LinkItemProps> = [
        { name: "Au quotidien", icon: null, path: "", button: false },
        { name: "À catégoriser", icon: AiOutlineCompass, path: "/categorize", button: true },
        { name: "Entités principales", icon: null, path: "", button: false },
        { name: "Utilisateurs", icon: AiOutlineUser, path: "/users", button: true },
        { name: "Actifs", icon: AiOutlineInbox, path: "/assets", button: true },
        { name: "Paramètres d'étude", icon: null, path: "", button: false },
        { name: "Secteurs", icon: AiOutlineTags, path: "/activity-groups", button: true },
        { name: "Activités", icon: AiOutlineDribbble, path: "/activities", button: true },
        { name: "Enseignes", icon: AiOutlineShop, path: "/brands", button: true },
    ];
    return (LinkItems);
}


const MenuLinks = () => {

    const location = useLocation();

    const {loginUser} = useUserStore();
    const [menuLinks, setMenuLinks] = useState<Array<LinkItemProps>>(
        (loginUser && loginUser?.providerName === "google" && loginUser?.email.endsWith("@go-yourban.com")) ? LinkItemsAdminGenerator() : LinkItemsGenerator()
    );

    
    
    return (
        <Box
        w={"100%"}
        px={"4"}
        mt={"20px"}
        >
            <YBDivider />

            {menuLinks && menuLinks.map((link: LinkItemProps, index) => {
                return (
                link.button === false ?
                    <NavTagItem key={index}>
                        {link.name}
                    </NavTagItem>
                    :
                    location.pathname === link.path ?
                        <NavItem key={index} display={{ base: "none", md: "block" }} path={link.path} icon={link.icon} selected={true}>
                            {link.name}
                        </NavItem> :
                        <NavItem key={index} display={{ base: "none", md: "block" }} path={link.path} icon={link.icon} selected={false}>
                            {link.name}
                        </NavItem>
            )})}
        </Box>
    )
}

export default MenuLinks;