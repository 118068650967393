import { create } from 'zustand';
import { Asset, InitialAssets } from '../types/Asset';
import { getIdInArray } from '../services/Helpers';

export type Actions = {
    addAsset: (asset: Asset) => void;
    setAssets: (assets: Array<Asset>) => void;
    setAsset: (asset: Asset) => void;
    deleteAsset: (assetId: number) => void;
}

const state: InitialAssets = {
    assets: [],
}

const useAssetsStore = create<InitialAssets & Actions>((set) => ({
    ...state,

    addAsset(asset: Asset) {
        set((state) => {
            return {...state, assets: [...state.assets, asset]};
        });
    },

    setAssets(assets: Array<Asset>) {
        set((state) => {
            return {...state, assets: [...assets]};
        });
    },
    
    setAsset(asset: Asset) {
        set((state) => {
            state.assets[getIdInArray(state.assets, asset.id)] = asset;
            return {...state, assets: [...state.assets]};
        });
    },

    deleteAsset(assetId: number) {
        set((state) => {
            state.assets.splice(getIdInArray(state.assets, assetId), 1)
            return { ...state, assets: [...state.assets] };
        });
    }
}))

export default useAssetsStore;