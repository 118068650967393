import React from "react";

import { Link } from "react-router-dom";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";

import { Box, Flex, Image, Menu, MenuItem, MenuList } from '@chakra-ui/react';

import YBSecondaryMenuButton from "../atoms/ChakraOverwrite/YBSecondaryMenuBouton";
import YBText from "../atoms/ChakraOverwrite/YBText";

import useUserStore from "../../storage/userStore";

import YOUrbanLogo from "../../assets/YOUrbanLogo.png";
import Favicon from "../../assets/favicon.png"
import MenuLinks from "./MenuLinks";


const Sidebar = () => {
    const { loginUser } = useUserStore();

    return (
        <Flex
            flexDir={"column"}
            h={"100%"}
            w={{ base: "80px", md: "250px" }}
            display={{ base: "none", sm: "flex" }}
            justifyContent={"space-between"}
            position={"fixed"}
            backgroundColor={"white"}
        >
            <Box>
                <Link to="/">
                    <Flex
                        bottom="0"
                        h={"10vh"}
                        px={{ base: "20px", md: "30px" }}
                        justifyContent="center"
                    >
                        <Image display={{ base: "none", md: "block" }} src={YOUrbanLogo} alt="Logo" objectFit={"contain"} minW={"100px"} />
                        <Image display={{ base: "block", md: "none" }} src={Favicon} alt="Logo" objectFit={"contain"} minW={"20px"} />
                    </Flex>
                </Link>
                <MenuLinks />
            </Box>
            <Menu matchWidth={true}>
                <YBSecondaryMenuButton
                    py={2}
                    h={"50px"}
                    background={"white"}
                    borderRadius={"0px"}
                    borderTop={"solid 1px lightgrey"}
                >
                    <YBText textAlign={"left"} display={{ base: "none", md: "block" }}>{loginUser?.firstName} {loginUser?.name}</YBText>
                    <Box display={{ base: "block", md: "none" }}>
                        <AiOutlineUser />
                    </Box>
                </YBSecondaryMenuButton>
                <MenuList
                    border={"solid 1px lightgrey"}
                    minW="0"
                    w={"100%"}
                >
                    <Link to={"/settings/user"}>
                        <MenuItem
                            w={"100%"}
                            justifyContent={{ base: "center", md: "start" }}
                            backgroundColor={"#ffffff"}
                            _hover={{ background: "#edf2f7" }}
                        >
                            <YBText display={{ base: "none", md: "block" }}>{"Paramètres"}</YBText>
                            <Box display={{ base: "block", md: "none" }}>
                                <AiOutlineUser />
                            </Box>
                        </MenuItem>
                    </Link>
                    <MenuItem
                        w={"100%"}
                        justifyContent={{ base: "center", md: "start" }}
                        backgroundColor={"#ffffff"}
                        _hover={{ background: "#edf2f7" }}
                        onClick={() => {
                            localStorage.removeItem("user");
                            window.location.reload();
                        }}>
                        <YBText display={{ base: "none", md: "block" }}>{"Déconnexion"}</YBText>
                        <Box display={{ base: "block", md: "none" }}>
                            <AiOutlineLogout />
                        </Box>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}

export default Sidebar;