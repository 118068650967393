import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export default function YBText(props: PropsWithChildren<TextProps>) {
  const { children, ...rest } = props;
  return (
    <Text fontFamily={"Poppins"} {...rest}>
      {children}
    </Text>
  );
}
