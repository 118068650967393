import React, { PropsWithChildren } from "react";

import { Text, TextProps } from "@chakra-ui/react"

export default function NavTagItem (props: PropsWithChildren<TextProps>) {
    const { children, ...rest } = props;

    return (
        <Text
            mt="4"
            mb="2"
            color="grey"
            fontSize={"12px"}
            fontWeight={"700"}
            display={{ base: "none", md: "block" }}
            {...rest}
        >
            {children}
        </Text>
    );
};