import React from "react";

import { GoogleLogin } from '@react-oauth/google';

import { sendCredentialsToBackEnd } from "../../services/GoogleServices";
import useUserStore from "../../storage/userStore";

const OneTapGoogleLogin = ({setSuccess}: {setSuccess: (success: boolean) => void}) => {
    const { setLoginUser } = useUserStore();

    const handleCredentials = async (credentialResponse: any) => {
        const { response } = await sendCredentialsToBackEnd(credentialResponse);
        setLoginUser({...response});
        setSuccess(true)
    }

    return (
        <GoogleLogin
            onSuccess={(credentialResponse) => {
                handleCredentials(credentialResponse)
            }}
        />
    );
}

export default OneTapGoogleLogin;