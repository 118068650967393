import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins";;

const overrides = extendTheme({
    styles: {
        global: () => ({
            body: {
                fontFamily: '"Poppins", "Inter", sans-serif"'
            },
        }),
    },
})

const GlobalTheme = extendTheme(overrides)

export default GlobalTheme;