import React, { PropsWithChildren } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

export default function YBPrimaryButton(props: PropsWithChildren<ButtonProps>) {
  const { children, ...rest } = props;

  return (
    <Button 
      color={"white"}
      borderRadius="10px"
      border={"none"}
      cursor="pointer"
      backgroundColor={"#585AFC"}
      bgGradient={"linear(130deg, #585AFC, 585AFC90"}
      _hover={{
        backgroundColor: "#23235E",
      }}
      _focus={{
        bg: "#7B61FF",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
