import React from "react";
import { Input, InputProps } from "@chakra-ui/react";

type Props = InputProps & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export default function YBInput(props: Props) {
  const {...rest} = props;

  return (
    <Input
      focusBorderColor={"#292A81"}
      borderColor={"#585AFC"}
      borderWidth={"2px"}
      backgroundColor={"white"}
      minWidth={"100px"}
      width={"100%"}
      textColor="black"
      height={"50px"}
      borderRadius={"8px"}
      {...rest}  />
  );
}
