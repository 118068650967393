import React from "react";
import { Flex, Icon, Box } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { NavItemProps } from "../../../types/Layout";

export default function NavItem({ icon, children, path, selected, display, ...rest }: NavItemProps) {
    return (
        <Link to={path} style={{ color: "white", textDecoration: "None" }}>
            <Flex
                align="center"
                py="2"
                pl="4"
                mb="1"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color={selected ? "white" : "#23235E"}
                bgGradient={selected ?  "linear(130deg, #585AFC, #585AFC90)" : undefined}
                _hover={{
                    background: "rgba(150, 150, 150, 0.30)",
                    textColor: "#23235E"
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        color={selected ? "white" : "#23235E"}
                        as={icon}
                    />
                )}
                <Box display={display}>
                    {children}
                </Box>
            </Flex>
        </Link>
    );
};