import { FunctionComponent, lazy } from 'react';

const HomePage = lazy(() => import('../pages/HomePage'));
const AssetsPage = lazy(() => import('../pages/AssetsPage'));
const AssetDetailsPage = lazy(() => import('../pages/AssetDetailsPage'));
const AssetKPIPage = lazy(() => import('../pages/AssetKPIPage'));
const UsersPage = lazy(() => import('../pages/UsersPage'));
const UserDetailsPage = lazy(() => import('../pages/UserDetailsPage'));
const SearchIdeaDetailsPage = lazy(() => import('../pages/SearchIdeaDetailsPage'));
const StudyZoneDetailPage = lazy(() => import('../pages/StudyZoneDetailPage'));
const ActivityGroupsPage = lazy(() => import('../pages/ActivityGroupsPage'));
const ActivitiesInGroupPage = lazy(() => import('../pages/ActivitiesInGroupPage'));
const ActivitiesPage = lazy(() => import('../pages/ActivitiesPage'));
const ActivityPage = lazy(() => import('../pages/ActivityPage'));
const CategorizePage = lazy(() => import('../pages/CategorizePage'));
const BrandsPage = lazy(() => import('../pages/BrandsPage'));
const BrandPage = lazy(() => import('../pages/BrandPage'));

type routesType = {
  name: string;
  path: string;
  element: FunctionComponent;
}[];

const routes: routesType = [
  {
    name: "Home",
    path: "/",
    element: HomePage,
  },
  {
    name: "Assets",
    path: "/assets",
    element: AssetsPage,
  },
  {
    name: "Détails d'un asset",
    path: "/asset-details/:id",
    element: AssetDetailsPage,
  },
  {
    name: "KPIs d'un asset",
    path: "/kpi/:id",
    element: AssetKPIPage,
  },
  {
    name: "Utilisateurs",
    path: "/users",
    element: UsersPage,
  },
  {
    name: "Détails d'un utilisateur",
    path: "/user-details/:id",
    element: UserDetailsPage,
  },
  {
    name: "Zones d'études",
    path: "/study-zone/:id",
    element: StudyZoneDetailPage,
  },
  {
    name: "Search Ideas",
    path: "/search-idea/:id",
    element: SearchIdeaDetailsPage,
  },
  {
    name: "Activity Groups",
    path: "/activity-groups",
    element: ActivityGroupsPage
  },
  {
    name: "Activities",
    path: "/activity-groups/:id",
    element: ActivitiesInGroupPage
  },
  {
    name: "Activities",
    path: "/activities",
    element: ActivitiesPage
  },
  {
    name: "Activity",
    path: "/activity/:id",
    element: ActivityPage
  },
  {
    name: "ToCategorize",
    path: "/categorize",
    element: CategorizePage
  },
  {
    name: "Brands",
    path: "/brands",
    element: BrandsPage
  },
  {
    name: "Brand",
    path: "/brand/:id",
    element: BrandPage
  }
];

export default routes;
