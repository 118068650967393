import axios from "axios";

import { BackendServiceReturn } from "../types/BackendServiceReturn";

export const sendCredentialsToBackEnd = async (credentialResponse: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, credentialResponse, { headers: {} });
        return ({ response: response.data, error: null } as BackendServiceReturn);
    } catch (error: any) {
        return ({ response: null, error } as BackendServiceReturn);
    }
}