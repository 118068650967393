import React from "react";
import { FormLabel, FormLabelProps } from "@chakra-ui/react";

type Props = FormLabelProps & {
  label?: string;
};

export default function YBLabel(props: Props) {
  const {label, ...rest} = props;

  return (
    <FormLabel {...rest}>
      {label}
    </FormLabel>
  );
}
