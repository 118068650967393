import { create } from 'zustand';
import { InitialUsers, LoginUser, User } from '../types/User';
import { getIdInArray } from '../services/Helpers';

export type Actions = {
    addUser: (user: User) => void;
    setUser: (user: User) => void;
    setUsers: (users: User[]) => void;
    setLoginUser: (user: LoginUser) => void;
    getLoginUser: () => LoginUser | null,
    deleteUser: (userId: number) => void;
    deleteLoginUser: () => void;
}

const state: InitialUsers = {
    loginUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null,
    users: []
}

const useUserStore = create<InitialUsers & Actions>((set) => ({
    ...state,

    addUser: (user: User) => {
        set((state) => {
            return {...state, users: [...state.users, user]};
        });
    },

    setLoginUser: (user: LoginUser) => {
        set((state) => {
            localStorage.setItem("user", JSON.stringify(user))
            return {
                ...state,
                loginUser: user
            };
        });
    },

    getLoginUser: () => {
        return (state.loginUser)
    },

    setUser(user: User) {
        set((state) => {
            state.users[getIdInArray(state.users, user.id)] = user;
            return {...state, users: [...state.users]};
        });
    },

    setUsers(users: User[]) {
        set((state) => {
            return {
                ...state,
                users: [...users]
            };
        });
    },

    deleteUser(userId: number) {
        set((state) => {
            state.users.splice(getIdInArray(state.users, userId), 1)
            return { ...state, users: [...state.users] };
        });
    },

    deleteLoginUser() {
        set((state) => {
            localStorage.removeItem("user")
            return {
                ...state,
                user: null
            };
        });
    }
}))

export default useUserStore;