import React from "react";

import { Box, Divider, DividerProps, Flex } from "@chakra-ui/react";

type Props = DividerProps & {
  label?: string | number;
};
export default function YBDivider(props: Props) {
  const { label, ...rest } = props;
  return (
    <Box display="relative" my='3' {...rest}>
      {label ?
        <Flex justifyContent={"space-between"} alignItems={"center"} >
          <Divider borderColor={"grey"} w={"40%"} />
          {label}
          <Divider borderColor={"grey"} w={"40%"} />
        </Flex> :
        <Divider borderColor={"grey"} />
      }
    </Box>
  );
}
